// Toggle Menu
// -----------------------------------------------------------------------
var currentScroll;
$('.js-nav-toggle').click(function() {
	$(this).toggleClass('is-active');
	$('.js-nav').toggleClass("is-active");
	if($('.js-nav-toggle').hasClass('is-active')) {
		currentScroll = $(window).scrollTop();
		$('.wrap').css( {
			position: 'fixed',
			width: '100%',
			top: -1*currentScroll
		});
	} else {
		$('.wrap').attr( { style: '' } );
		$(window).scrollTop(currentScroll);
	}
});

$(window).on('load resize', function() {
	var ww = $(window).width();
	if(ww < 641) {
		$('.gnav .child li a ').click(function() {
			$('.js-nav-toggle').removeClass("is-active");
				$('.js-nav').removeClass("is-active");
			$('.wrap').attr( { style: '' } );
			$(window).scrollTop(currentScroll);
		});
	}
});
