// Accordion
// -----------------------------------------------------------------------
$('.js-accordion-menu').click(function() {
	var ww = $(window).width();
	if(ww < 641) {
		$(this).toggleClass('is-active');
		$(this).next('.js-accordion-content').slideToggle();
		$(this).next('.js-accordion-content').toggleClass('is-active');
	}
});
