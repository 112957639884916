// slick-carousel
// https://www.npmjs.com/package/slick-carousel
//==================================================
// Load CSS (From node_module)
import 'slick-carousel/slick/slick.css';
import slick from 'slick-carousel';

$('.slider').slick({
  dots: false,
	arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
	autoplay: true,
  cssEase: 'linear'
});
