// Require module (from node_modules)
// ---------------------------------------------------------------------------
window.$ = window.jQuery = window.jquery = $;
// require('jquery.easing');
// require('./vendor/_vue.js');
// require('./vendor/_smooth-scroll.js');
require('./vendor/_slick.js');
// require('./vendor/_flickity.js');
// require('./vendor/_inview.js');
// require('./vendor/_heightline.js');
// require('./vendor/_vegas.js');
// require('./vendor/_animocons.js');
// require('./vendor/_lightbox2.js');
// require('./vendor/_colorbox.js');
// require('./vendor/_vue.js');
// require('./vendor/_tooltipster.js');
// require('./vendor/_fullpage.js');
// require('font-awesome/css/font-awesome.min.css');


// Require module (from libs folder)
// ---------------------------------------------------------------------------
require('./libs/_accordion.js');
// require('./libs/_fix-header.js');
require('./libs/_gnav-toggle.js');
// require('./libs/_googlemap.js');


$(window).on("scroll", function(){
    $(".header").css("left", -$(window).scrollLeft());
});
